import React, { useEffect, useState } from "react"
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import {navigate} from "gatsby"
import "../blogs/blogstyle.css"
import { blogList } from "../../components/Api/ListingApi";
import { Link } from "@reach/router";
import icon from "../../assets/img/home/icon.svg"
import moment from "moment-timezone";

function Blog() {

    const [storage, setStorage] = useState(false);
    const [listBlog, setListBlog] = useState([])
    useEffect(() => {
        const storedData = localStorage.getItem("userInfo");
        if (!storedData) {
            setStorage(false);
        } else {
            setStorage(true);
        }
        blog()
        // list()
    }, []);
    const blog = () => {
        let getParam = {
            pagesize: 20,
            page: 1,
            sort_value:-1,
            sort_field: "updatedAt"
        }
        blogList(getParam).then((res) => {
            // console.log(res.data.data);
            setListBlog(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }
    const convertTime=(createdAt)=>{
        const date = moment(createdAt, "DD/MM/YYYY");
        const formattedDate = date.format("MMMM D, YYYY");
        
        // console.log(formattedDate);
return formattedDate
      
    }
    const handleNavigate=(id)=>{
        navigate(`/blog/${id}`)
    }
    return (
        <div>
            <Navbar isLogin={storage} />
            {/* <div> */}

                {/* <span className="atf-scroll-top atf-back-to-top" data-targets="html" id="backtotop">
                    <i className="fa fa-angle-up atf-scrollup-icon"></i>
                </span>
                <div className="Secondary-page bloglist">
                    <div className="atf-special-offer terms-section">
                        <div className="container">
                            <div className="row section-vertical-middle py-3">
                                <div className="col-xl-12 col-lg-12 col-12 text-center">
                                    <h1 className="blog-title ConttentTitile">The latest coverage on housing trends and market trends</h1>
                                </div>
                                <div className="row section-vertical-top ">
                                    <div className="col-xl-9 col-lg-9 col-9 text-left">
                                        {listBlog.map((item, i) => (

                                            <div className="col-xl-12 col-lg-12 col-12 text-left" key={i}>
                                                <div className="atf-special-content atf-section-title my-auto mb-5 p-5 blog-border">
                                                    <h1 >{item.title}</h1>
                                                    <span>December 28, 2022</span>
                                                    <div className="atf-main-btn mt-3 text-left">
                                                        <p className=" fontStyleForContent" style={{ fontSize: "20px" }}>{item.content.replace(/<[^>]*>/g, '').slice(0, 350).replace(/&nbsp;/g, " ")}</p>
                                                        <Link to={`/blogDetail/${item._id}`} state={{ props: item, index: i }}
                                                            className="page-scroll atf-themes-btn " style={{ textDecoration: "none" ,fontFamily:"roboto , sans-serif",cursor: "pointer"}}>Continue Reading</Link>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-3 text-left"> */}
                                        {/* <div className="col-xl-12 col-lg-12 col-12 text-left"> */}
                                        {/* <div className="atf-special-content atf-section-title my-auto mb-5 p-5 blog-border" >
                                            <h1>Recent Blogs</h1> */}

                                            {/* {listBlog.map((item) => (
                                                <div className="d-flex" style={{alignItems: "baseline"}}> */}
                                                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                                                   {/* <i className="fa fa-chevron-right fa-regular" style={{color: "#010204",paddingRight:"10px"}}></i>
                                                    <p className="recentBlogs">{item.title} Real Estate</p>
                                                </div>
                                            ))}

                                        </div> */}
                                        {/* </div>  */}
                                    {/* </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}
            <div className="Secondary-page bloglist"> 
			<div className="atf-special-offer terms-section">
				<div className="container center-container2">
					<div className="row section-vertical-middle py-3">
						<div className="col-xl-12 col-lg-12 col-12 text-center">
                                  <h1 className="blog-title ConttentTitile">The latest coverage on housing trends and market trends</h1>
						 </div>
                         <div className="row section-vertical-top ">
                                    <div className="col-xl-9 col-lg-9 col-9 text-left">
                         {listBlog.map((item, i) => (
						<div className="col-xl-12 col-lg-12 col-12 text-left"> 
							<div className="atf-special-content atf-section-title  my-auto mb-5 p-4 blog-border">	
                                 <h2 >{item.title.charAt(0).toUpperCase()+ item.title.slice(1)}</h2>
	                 			<span>{convertTime(item.published_date)}</span>
                                 {/* <div className="highlight" dangerouslySetInnerHTML={{ __html: item.content.slice(0, 350)   }}></div> */}

	                 			<p className=" fontStyleForContent mt-2 " >{item.content.replace(/<[^>]*>/g, '').slice(0, 319).replace(/&nbsp;/g, " ")} ...</p>
	                 			<div className="atf-main-btn mt-3 text-left"> 
                                 <Link to={`/blog/${item.url}`} state={{ props: item, index: i }}
                                                            className="page-scroll atf-themes-btn " style={{ textDecoration: "none" ,fontFamily:"roboto , sans-serif",cursor: "pointer"}}>Continue Reading</Link>
                                                    
								</div>

							</div>
						</div>
))}
</div>
<div className="col-xl-3 col-lg-3 col-3 text-left">
                                        {/* <div className="col-xl-12 col-lg-12 col-12 text-left"> */}
                                        <div className="atf-special-content atf-section-title my-auto mb-5 p-4 blog-border" >
                                            <h2>Recent Blogs</h2>

                                            {listBlog.map((item,i) => (
                                                <div className="d-flex" style={{alignItems: "baseline"}}>
                                                   
                                                   <i className="fa fa-chevron-right fa-regular" style={{color: "#010204",paddingRight:"10px"}}></i>
                                                   <p onClick  ={()=>{handleNavigate(item.url)}} style={{cursor:"pointer"}} className="recentBlogs">{item.title}</p>
                                                 
                                                   {/* <Link to={`/blogDetail/${item.url}`} state={{ props: item, index: i }} className="recentBlogs" style={{textDecoration: "none", color:"black"}}>{item.title} Real Estate</Link> */}
                                                </div>
                                            ))}

                                        </div>
                                        {/* </div>  */}
                                    </div>
						</div>
					</div>
				</div>
			</div>
			
                </div>
            <Footer isLogin={storage} />
        </div>

    )
}

export default Blog